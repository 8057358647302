export const plBudgetOptions = {
  low: "10-20K PLN",
  medium: "20-80K PLN",
  high: "80K+ PLN",
};

export const enBudgetOptions = {
  low: "2.5-5K €",
  medium: "5-20K €",
  high: "20K+ €",
};
