import React, { useEffect, useRef } from "react";
import mediaQuery from "src/assets/styles/mediaQuery";
import ReactPlayer from "react-player";
import styled, { keyframes } from "styled-components";
import { StaticQuery, graphql } from "gatsby";
import distances from "src/assets/styles/distances.js";

import typographySizes from "src/assets/styles/typographySizes.js";

const Wrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  background-color: var(--bg);

  height: 100vh;
  width: 100vw;
  z-index: 99999999;
  opacity: 0;
  visibility: hidden;

  transition: opacity 0.5s cubic-bezier(0.65, 0, 0.35, 1);
  &.open {
    visibility: visible;
    opacity: 1;
    transition: opacity 0.8s cubic-bezier(0.65, 0, 0.35, 1);
  }
`;

const Close = styled.button`
  position: absolute;
  top: 20rem;
  right: 20rem;

  z-index: 9999999999;
  cursor: pointer;
  color: var(--rest);
  font-size: ${typographySizes.s}rem;
  @media (max-width: ${mediaQuery.tablet}) {
    top: ${distances.pageMarginSmall}rem;
    right: ${distances.pageMarginSmall}rem;
  }
`;

const ReelPlayer = ({ open, closeFn, lang }) => {
  const vidPlayer = useRef();
  const handlePause = () => {
    vidPlayer.current.seekTo(0);
  };

  return (
    <StaticQuery
      query={graphql`
        query ReelQuery {
          strapiGlobal {
            Showreel {
              localFile {
                publicURL
              }
            }
          }
        }
      `}
      render={(data) => (
        <Wrapper className={open && "open"}>
          <ReactPlayer
            ref={vidPlayer}
            playing={open}
            controls={false}
            url={data.strapiGlobal.Showreel.localFile.publicURL}
            width="100%"
            height="100%"
            playbackRate={!open == 0}
            onPause={handlePause}
            config={{
              file: {
                attributes: {
                  controlsList: "nodownload nofullscreen",
                },
              },
            }}
          />
          {/* <video autoPlay muted loop playsInline>
            <source
              src={data.strapiGlobal.Showreel.localFile.publicURL}
              type="video/mp4"
            />
          </video> */}
          <Close onClick={closeFn}>
            {" "}
            {lang == "en" ? "close" : "zamknij"}{" "}
          </Close>
        </Wrapper>
      )}
    />
  );
};

// const ReelPlayer = () => {
//   return <Wrapper></Wrapper>;
// };

export default ReelPlayer;
