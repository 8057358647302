import React from "react";
import { graphql, useStaticQuery } from "gatsby";
import styled, { keyframes } from "styled-components";
import Img from "gatsby-image";

//assets

//styles
import distances from "src/assets/styles/distances.js";
import mediaQuery from "src/assets/styles/mediaQuery";

//components
import Line from "src/components/global/Line.js";
import MainWrapper from "src/components/global/MainWrapper.js";

const Wrapper = styled.div`
  overflow: hidden;
  margin: 0 ${distances.pageMarginBig}rem;
  width: calc(100% - ${distances.pageMarginBig * 2}rem);
  position: relative;
  height: 100%;
  background-color: var(--bg);
  z-index: 101;
  &::before,
  &::after {
    content: '';
    position: absolute;
    top: 0;
    width: 150rem;
    height: 100%;
    z-index: 1;
    pointer-events: none;
  }

  &::before {
    left: 0;
    background: linear-gradient(to right, var(--bg), transparent);
  }

  &::after {
    right: 0;
    background: linear-gradient(to left, var(--bg), transparent);
  }

  @media (max-width: ${mediaQuery.tablet}) {
    margin: 0 15rem;
    width: calc(100% - 30rem);
  }
  @media (max-width: ${mediaQuery.tablet}px) {

  }
`;
const scroll = keyframes`
  0% { transform: translateX(0); }
  100% { transform: translateX(-100%); }
`;
const LogotypesWrapper = styled.div`
  display: flex;
  gap: 120rem;
  mix-blend-mode: exclusion;
  align-items: center;
  height: 120rem;

  @media (max-width: ${mediaQuery.tablet}) {
    gap: 30rem;
  }
`;

const ScrollingContent = styled.div`
  flex-shrink: 0;
  display: flex;
  gap: 120rem;
  animation: ${scroll} 50s linear infinite;
  align-items: center;

  @media (max-width: ${mediaQuery.tablet}) {
    gap: 30rem;
  }
`;

const Logo = styled(Img)`
  width: 120rem;
  height: 120rem;
  object-fit: contain;
  img {
    object-fit: contain !important;
  }
  @media (max-width: ${mediaQuery.tablet}) {
    width: 90rem;
    height: 90rem;
  }
`;

const Logotypes = ({ logos }) => {
  return (
    <>
      <Wrapper>
        <LogotypesWrapper>
          {Array.from({ length: 5 }).map((_, index) => (
            <ScrollingContent key={index}>
              {logos
                .slice(0, 15)
                .map((logo) =>
                  logo.ext === ".svg" ? (
                    <img
                      key={logo.name}
                      className="icon"
                      src={logo.Logo.localFile.publicURL}
                    />
                  ) : (
                    <Logo
                      loading="eager"
                      fluid={logo.Logo.localFile.childImageSharp.fluid}
                    />
                  )
                )}
            </ScrollingContent>
          ))}
        </LogotypesWrapper>
      </Wrapper>
      <MainWrapper>
        <Line />
      </MainWrapper>
    </>
  );
};

export default Logotypes;
