import * as React from "react";
import { graphql } from "gatsby";
import styled from "styled-components";

import mediaQuery from "src/assets/styles/mediaQuery.js";
import distances from "src/assets/styles/distances.js";
import Sketch from "src/components/p5/Sketch.js";
import Seo from "src/components/global/Seo.js";
import { Helmet } from "react-helmet";

import Nav from "src/components/global/Nav.js";

import Header from "src/components/home/Header.js";
import WhatWeDo from "src/components/home/WhatWeDo.js";
import About from "src/components/home/About.js";
import Projects from "src/components/global/Projects/Projects.js";
import Testimonial from "src/components/global/Testimonial.js";
import NewsAll from "src/components/global/News/NewsAll.js";
import Footer from "src/components/global/Footer.js";
import Logotypes from "src/components/global/Logotypes.js";
// markup

const Wrapper = styled.div`
  margin-bottom: 50rem;
  @media (max-width: ${mediaQuery.tablet}) {
    margin-bottom: ${distances.pageMarginSmall}rem;
  }
`;
const SketchWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  /* background-color: red; */
  pointer-events: none;
  z-index: 1;
  @media (max-width: ${mediaQuery.tablet}) {
    display: none;
  }
`;

const Home = ({ data, pageContext, location }) => {
  const queryData = data;
  const isMobile =
    typeof window !== "undefined" &&
    window.matchMedia(`(max-width: ${mediaQuery.tablet})`).matches;

  return (
    <div>
      {!isMobile && (
        <>
          <video
            muted
            loop
            playsInline
            controlsList="nodownload nofullscreen"
            style={{ display: "none" }}
            src={
              queryData.strapiPageHome.Header_video_Thumbnail.localFile
                .publicURL
            }
            preload="auto"
          ></video>
          <Helmet>
            <link
              rel="preload"
              as="video"
              type="video/mp4"
              href={
                queryData.strapiPageHome.Header_video_Thumbnail.localFile
                  .publicURL
              }
            />
          </Helmet>
        </>
      )}

      <Seo
        title={
          queryData.strapiPageHome.Seo && queryData.strapiPageHome.Seo.Title
        }
        description={
          queryData.strapiPageHome.Seo &&
          queryData.strapiPageHome.Seo.Description
        }
        image={
          queryData.strapiPageHome.Seo &&
          queryData.strapiPageHome.Seo.Img.localFile.publicURL
        }
      />

      <Wrapper>
        <Nav
          contactLink={queryData.strapiContactLink}
          newsLinkPage={queryData.strapiPageNews.Page_name}
          lang={pageContext.locale}
          langPageName={pageContext.langPageName}
          aboutPage={queryData.strapiPageAbout.Page_name}
          worksPage={queryData.strapiPageWork.Page_name}
          newsPage={queryData.strapiPageNews.Page_name}
          contactPage={queryData.strapiPageContact.Page_name}
          shopPage={queryData.strapiPageShop.Page_name}
          home
        />
        <SketchWrapper>
          <Sketch
            vidData={
              queryData.strapiPageHome.Header_video_Thumbnail.localFile
                .publicURL
            }
          />
        </SketchWrapper>
        <Header
          vidBg={
            queryData.strapiPageHome.Header_video_Thumbnail.localFile.publicURL
          }
          title={queryData.strapiPageHome.Header_title}
          subTitle={queryData.strapiPageHome.Header_SubTitle}
          vidText={queryData.strapiPageHome.Header_VideoText}
          socialLink={queryData.strapiContactLink.Social_link}
          lang={pageContext.locale}
        />
        <Logotypes logos={queryData.strapiPageAbout.Client} />
        <About
          title={queryData.strapiPageHome.About_title}
          infoText={queryData.strapiPageHome.About_paragraph}
          slider={queryData.strapiPageHome.About_slider}
          button={queryData.strapiPageHome.About_button}
          lang={pageContext.locale}
          pageName={queryData.strapiPageAbout.Page_name}
        />
        {/* <WhatWeDo
          imgs={queryData.strapiPageHome.What_we_do_img}
          title={queryData.strapiPageHome.What_we_do_title}
        /> */}
        <Projects
          title={queryData.strapiPageHome.Projects_title}
          infoText={queryData.strapiPageHome.Projects_paragraph}
          projects={queryData.allStrapiProject.edges}
          categories={queryData.strapiPageHome.categories}
          buttonText={queryData.strapiGlobal.Project_button}
          allButtonText={queryData.strapiPageHome.Projects_button}
          lang={pageContext.locale}
          worksPageName={queryData.strapiPageWork.Page_name}
        />

        <Testimonial
          lang={pageContext.locale}
          testimonials={queryData.allStrapiTestimonial}
        />

        <NewsAll
          news={queryData.allStrapiPost}
          allButtonText={queryData.strapiPageHome.News_button}
          pageName={queryData.strapiPageNews.Page_name}
          lang={pageContext.locale}
        />
        <Footer
          texts={queryData.strapiFooter}
          contactLink={queryData.strapiContactLink}
          newsLinkPage={queryData.strapiPageNews.Page_name}
          lang={pageContext.locale}
          aboutPage={queryData.strapiPageAbout.Page_name}
          worksPage={queryData.strapiPageWork.Page_name}
          newsPage={queryData.strapiPageNews.Page_name}
          contactPage={queryData.strapiPageContact.Page_name}
        />
      </Wrapper>
    </div>
  );
};

export default Home;

export const IndexQuery = graphql`
  query Home($locale: String!) {
    strapiGlobal(locale: { eq: $locale }) {
      Project_button
    }

    strapiPageHome(locale: { eq: $locale }) {
      Seo {
        Title
        Description
        Img {
          localFile {
            publicURL
          }
        }
      }
      Page_name
      Header_title
      Header_SubTitle
      Header_VideoText

      Header_video_Thumbnail {
        localFile {
          publicURL
        }
      }
      What_we_do_img {
        localFile {
          publicURL
        }
        width
        height
      }
      What_we_do_title
      About_title
      About_paragraph
      About_button
      About_slider {
        localFile {
          childImageSharp {
            fluid(quality: 100) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
          publicURL
        }
      }
      Projects_paragraph
      Projects_title
      Projects_button
      News_button
    }
    allStrapiProject(
      sort: { order: ASC, fields: Home_order }
      filter: { locale: { eq: $locale }, Home: { eq: true } }
    ) {
      edges {
        node {
          Title
          Intro_text
          categories {
            Name
          }
          Thumbnails {
            __typename
            ... on STRAPI__COMPONENT_PROJECT_SIGNLE {
              Single {
                localFile {
                  childImageSharp {
                    fluid(quality: 100) {
                      ...GatsbyImageSharpFluid_withWebp
                    }
                  }
                  publicURL
                }
              }
              Single_2 {
                localFile {
                  childImageSharp {
                    fluid(quality: 100) {
                      ...GatsbyImageSharpFluid_withWebp
                    }
                  }
                  publicURL
                }
              }
            }
            ... on STRAPI__COMPONENT_PROJECT_THUMBNAILS {
              Small_left {
                localFile {
                  childImageSharp {
                    fluid(quality: 100) {
                      ...GatsbyImageSharpFluid_withWebp
                    }
                  }
                  publicURL
                }
              }
              Big_right {
                localFile {
                  childImageSharp {
                    fluid(quality: 100) {
                      ...GatsbyImageSharpFluid_withWebp
                    }
                  }
                  publicURL
                }
              }
              Small_left_2 {
                localFile {
                  childImageSharp {
                    fluid(quality: 100) {
                      ...GatsbyImageSharpFluid_withWebp
                    }
                  }
                  publicURL
                }
              }
              Big_right_2 {
                localFile {
                  childImageSharp {
                    fluid(quality: 100) {
                      ...GatsbyImageSharpFluid_withWebp
                    }
                  }
                  publicURL
                }
              }
            }
            ... on STRAPI__COMPONENT_PROJECT_SMALL_RIGHT {
              Big_left {
                localFile {
                  childImageSharp {
                    fluid(quality: 100) {
                      ...GatsbyImageSharpFluid_withWebp
                    }
                  }
                  publicURL
                }
              }
              Small_right {
                localFile {
                  childImageSharp {
                    fluid(quality: 100) {
                      ...GatsbyImageSharpFluid_withWebp
                    }
                  }
                  publicURL
                }
              }
              Big_left_2 {
                localFile {
                  childImageSharp {
                    fluid(quality: 100) {
                      ...GatsbyImageSharpFluid_withWebp
                    }
                  }
                  publicURL
                }
              }
              Small_right_2 {
                localFile {
                  childImageSharp {
                    fluid(quality: 100) {
                      ...GatsbyImageSharpFluid_withWebp
                    }
                  }
                  publicURL
                }
              }
            }
          }
        }
      }
    }
    allStrapiTestimonial(
      sort: { order: ASC, fields: Home_order }
      filter: { locale: { eq: $locale }, Home_page: { eq: true } }
    ) {
      edges {
        node {
          Company_name
          Featured_text

          Home_page
          Person_name
          Clutch
          Testimonial {
            ... on STRAPI__COMPONENT_TESTIMONIAL_TEXT {
              Testimonial {
                data {
                  Testimonial
                }
              }
              strapi_component
            }
            ... on STRAPI__COMPONENT_TESTIMONIAL_VIDEO {
              Video {
                localFile {
                  publicURL
                }
              }
              strapi_component
            }
          }
          Logo {
            width
            height
            localFile {
              childImageSharp {
                fluid(quality: 100) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
          Person_img {
            localFile {
              childImageSharp {
                fluid(quality: 100) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
        }
      }
    }
    allStrapiPost(
      sort: { order: DESC, fields: Date }
      limit: 3
      filter: { locale: { eq: $locale } }
    ) {
      edges {
        node {
          Date(formatString: "DD.MM.YYYY")
          Title
          Thumbnail {
            localFile {
              childImageSharp {
                fluid(quality: 100) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
              publicURL
            }
          }
        }
      }
    }
    strapiFooter(locale: { eq: $locale }) {
      Mail_text
      Tel_text
      Top_text
    }

    strapiContactLink(locale: { eq: $locale }) {
      Main_mail
      Main_tel
      Social_link {
        Link
        Name
      }
      Map_link
      City
      Zip_code
      Street
      Map_text
    }
    strapiPageShop(locale: { eq: $locale }) {
      Page_name
    }
    strapiPageAbout(locale: { eq: $locale }) {
      Page_name

      Client {
        Logo {
          localFile {
          publicURL
            childImageSharp {
              fluid(quality: 100) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
      }
    }
    strapiPageNews(locale: { eq: $locale }) {
      Page_name
    }
    strapiPageWork(locale: { eq: $locale }) {
      Page_name
    }
    strapiPageContact(locale: { eq: $locale }) {
      Page_name
    }
  }
`;
