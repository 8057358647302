import React, { useState, useRef, useEffect, useMemo } from "react";
import { graphql } from "gatsby";
import styled from "styled-components";
import colors from "src/assets/styles/colors.js";
import Seo from "src/components/global/Seo.js";
import slugify from "slugify";

import Nav from "src/components/global/Nav.js";
import mediaQuery from "src/assets/styles/mediaQuery.js";
import distances from "src/assets/styles/distances.js";
import Project from "src/components/global/Projects/Project.js";
import ProjectGrid from "src/components/global/Projects/ProjectGrid.js";

import Title from "src/components/global/Typo/BigTitle.js";
import BigParagraph from "src/components/global/Typo/BigParagraph.js";
import Body from "src/components/global/Typo/Body.js";
import Line from "src/components/global/Line.js";
import listview from "src/assets/svg/view_list.svg";
import gridview from "src/assets/svg/grid_view.svg";

import MainWrapper from "src/components/global/MainWrapper.js";
import typographySizes from "../assets/styles/typographySizes";
import Button from "src/components/global/Button.js";
import Highlighted from "src/components/works/Highlighted.js";
import Animate from "src/components/global/Typo/Animate.js";
import AnimateBody from "src/components/global/Typo/AnimateBody.js";
import Footer from "src/components/global/Footer.js";
// markup

const Wrapper = styled.div`
  margin-bottom: 50rem;
  @media (max-width: ${mediaQuery.tablet}) {
    margin-bottom: 15rem;
  }
`;

const StyledTitle = styled(Title)`
  position: relative;
  left: -1vw;
`;
const TopWrapper = styled.div`
  padding-top: ${distances.topMargin}rem;
  @media (max-width: ${mediaQuery.tablet}) {
    padding-top: ${distances.topMarginMobile}rem;
  }
`;
const BottomWrapper = styled.div`
  margin-top: 50rem;
  display: flex;
  justify-content: space-between;
  margin-bottom: ${distances.sectionMarginBig}rem;
  @media (max-width: ${mediaQuery.laptop}) {
    flex-wrap: wrap;
    flex-direction: column-reverse;
    margin-bottom: ${distances.sectionMarginSmall}rem;
  }
`;

const StyledBigParagraph = styled(BigParagraph)`
  width: 85.71%;
  margin-top: 50rem;
  @media (max-width: ${mediaQuery.laptop}) {
    width: 100%;
  }
  @media (max-width: ${mediaQuery.tablet}) {
    margin-top: 20rem;
  }
`;
const TopSmallTextWrapper = styled.div`
  width: 18.75%;
  @media (max-width: ${mediaQuery.laptop}) {
    width: 50%;
    min-width: 260rem;
    margin-top: 50rem;
  }
`;
const TopSmallText = styled(Body)``;

const StyledSmallParagraph = styled(Body)`
  width: calc(25% - 20rem);
  @media (max-width: ${mediaQuery.desktop}) {
    width: 50%;
  }
  @media (max-width: ${mediaQuery.laptop}) {
    width: 80%;
  }
  @media (max-width: ${mediaQuery.tablet}) {
    width: 100%;
  }
`;

const HighlightedWorksTop = styled.div`
  width: 100%;
  display: flex;
  align-items: flex-end;
  margin-bottom: 50rem;
  @media (max-width: ${mediaQuery.tablet}) {
    justify-content: space-between;
  }
  @media (max-width: ${mediaQuery.tablet}) {
    margin-bottom: 20rem;
    ${Button} {
      display: none;
    }
  }
`;

const HighlightedTitle = styled.h4`
  font-size: ${typographySizes.sm}rem;
  color: var(--rest);
  width: calc(75% + 20rem);
  @media (max-width: ${mediaQuery.desktop}) {
    width: calc(50% + 0rem);
  }
  @media (max-width: ${mediaQuery.tablet}) {
    width: 70%;
  }
`;

const AllWorksSmallTitle = styled.h4`
  font-size: ${typographySizes.s}rem;
  color: var(--rest);
  margin-bottom: 15rem;
  @media (max-width: ${mediaQuery.tablet}) {
    margin-top: ${distances.sectionMarginSmall}rem;
  }
`;

const AllWorksBigTitle = styled(BigParagraph)`
  width: 85.71%;
  margin-top: 40rem;
  margin-bottom: 100rem;
  @media (max-width: ${mediaQuery.tablet}) {
    margin-top: 20rem;
    margin-bottom: 50rem;
    width: 100%;
  }
`;

const ProjectViewWrapper = styled.div`
  margin-bottom: 40rem;
  display: flex;
  align-items: center;
`;

const ProjectViewPos = styled.button`
  font-size: ${typographySizes.s}rem;
  color: var(--rest);
  display: flex;
  align-items: center;
  margin-right: 30rem;
  opacity: 0.2;
  transition: all 0.5s;
  cursor: pointer;
  &.active {
    opacity: 1;
    transition: all 0.5s;
  }
  :hover {
    opacity: 1;
    transition: all 0.5s;
  }
  img {
    margin-right: 10rem;
  }
`;

const AllProjets = styled.div`
  margin-bottom: ${distances.sectionMarginBig}rem;
  @media (max-width: ${mediaQuery.tablet}) {
    margin-bottom: ${distances.sectionMarginSmall}rem;
  }
`;
const AllProjetsGrid = styled(MainWrapper)`
  display: flex;
  overflow: hidden;
  padding-bottom: ${distances.sectionMarginBig}rem;
  align-items: flex-start;
  &.column-4 {
  }
  &.column-3 {
    display: none;
  }

  @media (max-width: ${mediaQuery.desktop}) {
    &.column-4 {
      display: none;
    }
    &.column-3 {
      display: flex;
    }
  }
  @media (max-width: ${mediaQuery.tablet}) {
    margin-bottom: ${distances.sectionMarginSmall}rem;
  }
`;

const GridProjetsCol = styled.div`
  width: calc(25% - ${(distances.gap * 3) / 4}rem);
  margin-right: ${distances.gap}rem;
  /* transition: 0.1s all; */
  &:nth-of-type(2n) {
    /* margin-top: 180rem; */
  }
  &:nth-of-type(4n) {
    margin-right: 0;
  }
  @media (max-width: ${mediaQuery.desktop}) {
    width: calc(33.33% - ${(distances.gap * 2) / 3}rem);
    margin-right: ${distances.gap}rem;
    &:nth-of-type(4n) {
      margin-right: ${distances.gap}rem;
    }
    &:nth-of-type(3n) {
      margin-right: 0;
    }
  }
`;

const HighlightedWorksBottom = styled(MainWrapper)`
  display: flex;
  flex-direction: row-reverse;
  display: none;
  @media (max-width: ${mediaQuery.tablet}) {
    display: flex;
  }
`;
const ButtonWrapper = styled.span`
  border: solid 1rem var(--rest);
  font-size: ${typographySizes.m}rem;
  text-align: center;
  color: var(--rest);
  align-self: center;
  cursor: pointer;
  /* margin-left: 50rem; */
  :last-of-type {
    margin-left: 30rem;
  }
  border-radius: 60rem;
  height: 51rem;
  overflow: hidden;
  width: 166rem;
  span {
    font-size: inherit;
    color: inherit;
    display: block;
    transition: all 0.5s cubic-bezier(0.65, 0, 0.35, 1);
  }
  :hover {
    span {
      transform: translateY(-100%);
      transition: all 0.5s cubic-bezier(0.65, 0, 0.35, 1);
    }
  }

  &.active {
    background: var(--rest);
    color: var(--bg);
    border: solid 1rem var(--bg);
  }

  @media (max-width: ${mediaQuery.tablet}) {
    font-size: ${typographySizes.s}rem;
    padding: 5rem 15rem;
    margin-left: 20rem;
    width: 50rem;
    height: 18rem;
  }
`;
const Projects = ({ data, pageContext }) => {
  const [showGridView, setShowGridView] = useState(false);
  const [mobileview, setMobile] = useState(false);

  const leftC = useRef(null);
  const midC = useRef(null);
  const rightC = useRef(null);
  const fourthC = useRef(null);
  const allGrid = useRef(null);
  const [lDist, setLDist] = useState(0);
  const [rDist, setRDist] = useState(0);
  const [mDist, setMDist] = useState(0);
  const [fDist, setFDist] = useState(0);

  const [moveL, setMoveL] = useState(0);
  const [moveR, setMoveR] = useState(0);
  const [moveM, setMoveM] = useState(0);
  const [moveF, setMoveF] = useState(0);

  const [containerGridHeight, setContainerGridHeight] = useState(0);
  const isBrowser = typeof window !== "undefined";
  let intViewportWidth = 0;
  let intViewportHeight = 0;
  if (isBrowser) {
    intViewportWidth = window.innerWidth;
    intViewportHeight = window.innerHeight;
  }

  const [headerH, setheaderH] = useState(null);
  const marginTopL = useMemo(() => intViewportWidth * 0.15, [intViewportWidth]);
  const marginTopM = useMemo(() => intViewportWidth * 0.2, [intViewportWidth]);
  const marginTopR = useMemo(() => intViewportWidth * 0.05, [intViewportWidth]);
  const marginTopF = useMemo(() => intViewportWidth * 0.25, [intViewportWidth]);
  // const marginTopL = 0;
  // const marginTopM = 0;
  // const marginTopR = 0;
  // const marginTopF = 0;

  useEffect(() => {
    if (isBrowser && intViewportWidth > 900 && showGridView) {
      const heightL = leftC.current.offsetHeight;
      const heightM = midC.current.offsetHeight;
      const heightR = rightC.current.offsetHeight;
      const heightF = fourthC.current.offsetHeight;

      const shortestHeight = Math.min(heightL, heightM, heightR, heightF);
      setContainerGridHeight(shortestHeight);
      let intViewportHeight = 0;

      if (isBrowser) {
        intViewportHeight = window.innerHeight;
      }

      const difrenceL = heightL + marginTopL - shortestHeight;
      const difrenceR = heightR + marginTopR - shortestHeight;
      const difrenceM = heightM + marginTopM - shortestHeight;
      const difrenceF = heightF + marginTopF - shortestHeight;

      const speedL = difrenceL / shortestHeight;
      const speedR = difrenceR / shortestHeight;

      const speedM = difrenceM / shortestHeight;

      const speedF = difrenceF / shortestHeight;
      setMoveL(difrenceL / shortestHeight);
      setMoveR(difrenceR / shortestHeight);
      setMoveM(difrenceM / shortestHeight);
      setMoveF(difrenceF / shortestHeight);

      const handleScroll = () => {
        if (
          allGrid.current.getBoundingClientRect().top - intViewportHeight <
          0
        ) {
          const moveSize =
            allGrid.current.getBoundingClientRect().top - intViewportHeight;

          setMDist(moveSize * speedM);
          setLDist(moveSize * speedL);
          setFDist(moveSize * speedF);
          setRDist(moveSize * speedR);
        }
      };
      window.addEventListener("scroll", handleScroll);
      return () => window.removeEventListener("scroll", handleScroll);
    }
  }, [showGridView]);

  function useInterval(callback, delay) {
    const savedCallback = useRef();

    // Remember the latest callback.
    useEffect(() => {
      savedCallback.current = callback;
    }, [callback]);

    // Set up the interval.
    useEffect(() => {
      function tick() {
        savedCallback.current();
      }
      if (delay !== null) {
        let id = setInterval(tick, delay);
        return () => clearInterval(id);
      }
    }, [delay]);
  }
  const urlLang =
    pageContext.locale === "pl"
      ? "/projekty/"
      : `/${pageContext.locale}/works/`;
  const columns4 = [[], [], [], []];
  const columns3 = [[], [], []];
  // Iterate over the array of elements
  data.allStrapiProject.edges.forEach((project, index) => {
    // Calculate the column index
    let columnIndex = index % 4;
    // if (index < 4 && data.allStrapiProject.edges.length % 4 != 2) {
    //   if (columnIndex === 0 || columnIndex === 2) {
    //     columnIndex = columnIndex + 1;
    //   }
    // }
    // if (index == 0 && data.allStrapiProject.edges.length % 4 == 2) {
    //   if (columnIndex === 0) {
    //     columnIndex = 3;
    //   }
    // }

    // Add the element to the column

    let slugTitle = slugify(project.node.Title, {
      lower: true,
      strict: true,
    });
    const workUrl = urlLang + slugTitle;
    columns4[columnIndex].push(
      <ProjectGrid
        title={project.node.Title}
        workUrl={workUrl}
        category={project.node.Highlighted_category}
        videoSrc={project.node.localFile && project.localFile.publicURL}
        imgSrc={
          project.node.Highlighted_thumbnail
            ? project.node.Highlighted_thumbnail.localFile.childImageSharp
                ?.fluid
            : project.node.Thumbnail_for_next.localFile.childImageSharp.fluid
        }
      />
    );
  });
  // Thumbnail_for_next
  // Iterate over the array of elements
  data.allStrapiProject.edges.forEach((project, index) => {
    // Calculate the column index
    let columnIndex = index % 3;

    // Add the element to the column
    let slugTitle = slugify(project.node.Title, {
      lower: true,
      strict: true,
    });
    const workUrl = urlLang + slugTitle;
    columns3[columnIndex].push(
      <ProjectGrid
        title={project.node.Title}
        workUrl={workUrl}
        category={project.node.Highlighted_category}
        videoSrc={project.node.localFile && project.localFile.publicURL}
        imgSrc={
          project.node.Highlighted_thumbnail
            ? project.node.Highlighted_thumbnail.localFile.childImageSharp
                ?.fluid
            : project.node.Thumbnail_for_next.localFile.childImageSharp.fluid
        }
      />
    );
  });

  useEffect(() => {
    if (isBrowser) {
      function handleResize() {
        if (window.innerWidth < 1024) {
          setShowGridView(false);
          setMobile(true);
        } else {
          setMobile(false);
        }
      }

      window.addEventListener("resize", handleResize);

      return () => window.removeEventListener("resize", handleResize);
    }
  }, []);

  useEffect(() => {
    if (isBrowser) {
      if (window.innerWidth < 1024) {
        setShowGridView(false);
        setMobile(true);
      }
    }
  }, []);

  return (
    <Wrapper>
      <Seo
        title={data.strapiPageWork.Seo && data.strapiPageWork.Seo.Title}
        description={
          data.strapiPageWork.Seo && data.strapiPageWork.Seo.Description
        }
        image={
          data.strapiPageWork.Seo &&
          data.strapiPageWork.Seo.Img.localFile.publicURL
        }
      />
      <Nav
        contactLink={data.strapiContactLink}
        newsLinkPage={data.strapiPageNews.Page_name}
        lang={pageContext.locale}
        langPageName={pageContext.langPageName}
        aboutPage={data.strapiPageAbout.Page_name}
        worksPage={data.strapiPageWork.Page_name}
        newsPage={data.strapiPageNews.Page_name}
        contactPage={data.strapiPageContact.Page_name}
        shopPage={data.strapiPageShop.Page_name}
      />
      <MainWrapper>
        <TopWrapper>
          <StyledTitle>
            <Animate text={data.strapiPageWork.Title} />
          </StyledTitle>
          <StyledBigParagraph className="intro-text">
            <AnimateBody text={data.strapiPageWork.Big_paragraph} />
          </StyledBigParagraph>
        </TopWrapper>
        <BottomWrapper>
          <TopSmallTextWrapper>
            <TopSmallText className="intro-text">
              <AnimateBody text={data.strapiPageWork.Small_info_text} />
            </TopSmallText>
          </TopSmallTextWrapper>
          <StyledSmallParagraph className="intro-text">
            <AnimateBody text={data.strapiPageWork.Small_paragraph} />
          </StyledSmallParagraph>
        </BottomWrapper>
        <HighlightedWorksTop>
          <HighlightedTitle>
            {data.strapiPageWork.Highlighted_works_title}
          </HighlightedTitle>
          <Button to={data.strapiPageWork.Cta_link}>
            {data.strapiPageWork.Cta_name}
          </Button>
        </HighlightedWorksTop>
      </MainWrapper>
      <Highlighted
        slider={data.HighlightedProject.edges}
        lang={pageContext.locale}
      />
      <HighlightedWorksBottom>
        <Button to={data.strapiPageWork.Cta_link}>
          {data.strapiPageWork.Cta_name}
        </Button>
      </HighlightedWorksBottom>
      <MainWrapper>
        <AllWorksSmallTitle>
          {" "}
          {data.strapiPageWork.Works_title_small}
        </AllWorksSmallTitle>
        <Line />
        <AllWorksBigTitle>
          {" "}
          {data.strapiPageWork.Works_title_big}
        </AllWorksBigTitle>
        {!mobileview && (
          <ProjectViewWrapper>
            <ButtonWrapper
              className={!showGridView && "active"}
              onClick={() => {
                setShowGridView(false);
              }}
            >
              <span>{pageContext.locale === "pl" ? "lista" : `list`}</span>
              <span>{pageContext.locale === "pl" ? "lista" : `list`}</span>
            </ButtonWrapper>
            <ButtonWrapper
              className={showGridView && "active"}
              onClick={() => {
                setShowGridView(true);
              }}
            >
              <span>{pageContext.locale === "pl" ? "siatka" : `grid`}</span>

              <span>{pageContext.locale === "pl" ? "siatka" : `grid`}</span>
            </ButtonWrapper>
          </ProjectViewWrapper>
        )}
      </MainWrapper>
      {!showGridView && (
        <AllProjets>
          {data.allStrapiProject.edges.map((project) => (
            <Project
              title={project.node.Title}
              thumbnails={project.node.Thumbnails}
              introText={project.node.Intro_text}
              categories={project.node.categories}
              buttonText={data.strapiGlobal.Project_button}
              lang={pageContext.locale}
            />
          ))}
        </AllProjets>
      )}
      {showGridView && (
        <>
          {" "}
          <AllProjetsGrid
            style={{
              height: `${containerGridHeight}rem`,
            }}
            ref={allGrid}
            className="column-4"
          >
            {/* {columns4.map((column, index) => (
          <GridProjetsCol key={index}>{column}</GridProjetsCol>
        ))} */}

            <GridProjetsCol
              style={{
                marginTop: `${marginTopL}rem`,
                transform: `translate3d(0, ${lDist}px, 0)`,
              }}
              ref={leftC}
            >
              {columns4[0]}
            </GridProjetsCol>
            <GridProjetsCol
              style={{
                marginTop: `${marginTopM}rem`,
                transform: `translate3d(0, ${mDist}px, 0)`,
              }}
              ref={midC}
            >
              {columns4[1]}
            </GridProjetsCol>
            <GridProjetsCol
              style={{
                marginTop: `${marginTopR}rem`,

                transform: `translate3d(0, ${rDist}px, 0)`,
              }}
              ref={rightC}
            >
              {columns4[2]}
            </GridProjetsCol>
            <GridProjetsCol
              style={{
                marginTop: `${marginTopF}rem`,

                transform: `translate3d(0, ${fDist}px, 0)`,
              }}
              ref={fourthC}
            >
              {columns4[3]}
            </GridProjetsCol>
          </AllProjetsGrid>
          <AllProjetsGrid className="column-3">
            {columns3.map((column, index) => (
              <GridProjetsCol key={index}>{column}</GridProjetsCol>
            ))}
          </AllProjetsGrid>
        </>
      )}

      <Footer
        texts={data.strapiFooter}
        contactLink={data.strapiContactLink}
        aboutPage={data.strapiPageAbout.Page_name}
        worksPage={data.strapiPageWork.Page_name}
        newsPage={data.strapiPageNews.Page_name}
        contactPage={data.strapiPageContact.Page_name}
        lang={pageContext.locale}
      />
    </Wrapper>
  );
};

export default Projects;

export const IndexQuery = graphql`
  query ProjectsPage($locale: String!) {
    strapiGlobal(locale: { eq: $locale }) {
      Project_button
    }
    HighlightedProject: allStrapiProject(
      sort: { fields: Highlighted_order, order: ASC }
      filter: { Works_highlighted: { eq: true }, locale: { eq: $locale } }
    ) {
      edges {
        node {
          Highlighted_category
          Coming_soon
          Highlighted_thumbnail {
            localFile {
              childImageSharp {
                fluid(quality: 100) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
              publicURL
            }
          }
          Title
        }
      }
    }
    allStrapiProject(
      sort: { order: DESC, fields: Date_of_publication }
      filter: { locale: { eq: $locale }, Coming_soon: { ne: true } }
    ) {
      edges {
        node {
          Title
          Intro_text
          categories {
            Name
          }
          Highlighted_category
          Thumbnail_for_next {
            localFile {
              childImageSharp {
                fluid(quality: 100) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
              publicURL
            }
          }
          Highlighted_thumbnail {
            localFile {
              childImageSharp {
                fluid(quality: 100) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
              publicURL
            }
          }
          Thumbnails {
            ... on STRAPI__COMPONENT_PROJECT_SIGNLE {
              Single {
                localFile {
                  childImageSharp {
                    fluid(quality: 100) {
                      ...GatsbyImageSharpFluid_withWebp
                    }
                  }
                  publicURL
                }
              }
              Single_2 {
                localFile {
                  childImageSharp {
                    fluid(quality: 100) {
                      ...GatsbyImageSharpFluid_withWebp
                    }
                  }
                  publicURL
                }
              }
            }
            ... on STRAPI__COMPONENT_PROJECT_THUMBNAILS {
              Small_left {
                localFile {
                  childImageSharp {
                    fluid(quality: 100) {
                      ...GatsbyImageSharpFluid_withWebp
                    }
                  }
                  publicURL
                }
              }
              Big_right {
                localFile {
                  childImageSharp {
                    fluid(quality: 100) {
                      ...GatsbyImageSharpFluid_withWebp
                    }
                  }
                  publicURL
                }
              }
              Small_left_2 {
                localFile {
                  childImageSharp {
                    fluid(quality: 100) {
                      ...GatsbyImageSharpFluid_withWebp
                    }
                  }
                  publicURL
                }
              }
              Big_right_2 {
                localFile {
                  childImageSharp {
                    fluid(quality: 100) {
                      ...GatsbyImageSharpFluid_withWebp
                    }
                  }
                  publicURL
                }
              }
            }
            ... on STRAPI__COMPONENT_PROJECT_SMALL_RIGHT {
              Big_left {
                localFile {
                  childImageSharp {
                    fluid(quality: 100) {
                      ...GatsbyImageSharpFluid_withWebp
                    }
                  }
                  publicURL
                }
              }
              Small_right {
                localFile {
                  childImageSharp {
                    fluid(quality: 100) {
                      ...GatsbyImageSharpFluid_withWebp
                    }
                  }
                  publicURL
                }
              }
              Big_left_2 {
                localFile {
                  childImageSharp {
                    fluid(quality: 100) {
                      ...GatsbyImageSharpFluid_withWebp
                    }
                  }
                  publicURL
                }
              }
              Small_right_2 {
                localFile {
                  childImageSharp {
                    fluid(quality: 100) {
                      ...GatsbyImageSharpFluid_withWebp
                    }
                  }
                  publicURL
                }
              }
            }
          }
        }
      }
    }
    strapiPageWork(locale: { eq: $locale }) {
      Seo {
        Title
        Description
        Img {
          localFile {
            publicURL
          }
        }
      }
      Small_info_text
      Small_paragraph
      Big_paragraph
      Title
      Cta_link
      Cta_name
      Highlighted_works_title
      Works_title_small
      Works_title_big
      Page_name
    }
    strapiFooter(locale: { eq: $locale }) {
      Mail_text
      Tel_text
      Top_text
    }

    strapiContactLink(locale: { eq: $locale }) {
      Main_mail
      Main_tel
      Social_link {
        Link
        Name
      }
      Map_link
      City
      Zip_code
      Street
      Map_text
    }
    strapiPageAbout(locale: { eq: $locale }) {
      Page_name
    }
    strapiPageNews(locale: { eq: $locale }) {
      Page_name
    }

    strapiPageContact(locale: { eq: $locale }) {
      Page_name
    }
    strapiPageShop(locale: { eq: $locale }) {
      Page_name
    }
  }
`;
